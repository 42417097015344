<template>
    <div>
        <el-row :gutter="10">
            <el-collapse-transition>
                <div v-show="instanceVisible">
                    <el-col :span="21">
                        <el-table max-height="402" :data="instanceData" style="width: 100%">
                            <el-table-column prop="instanceUuid" label="实例Uuid" align="center">
                            </el-table-column>
                            <el-table-column prop="instanceName" label="实例名称" align="center">
                            </el-table-column>
                            <el-table-column prop="image" label="实例镜像" align="center">
                            </el-table-column>
                            <el-table-column prop="instancePaymentMode" label="付费模式" align="center">
                                <template slot-scope="scope">
                                    <el-tag type="info">{{ scope.row.instancePaymentMode }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="instanceExpiresTime" label="到期时间" align="center">
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-button @click="handleClick(scope.row)" type="text" size="small"
                                        icon="el-icon-view">查看</el-button>
                                </template>
                            </el-table-column>
                        </el-table>

                    </el-col>
                    <el-col :span="3">
                        <el-card shadow="hover" style="height: 402px">
                            <el-row type="flex" justify="center">
                                <el-descriptions title="面板用户信息" direction="vertical" :column="1" border size="mini">
                                    <el-descriptions-item label="面板用户名">{{ panelUser.panelUserName }}</el-descriptions-item>
                                    <el-descriptions-item label="当前积分">暂未实现</el-descriptions-item>
                                </el-descriptions>
                            </el-row>
                            <el-row type="flex" justify="center" style="margin-top: 10px">
                                <el-button type="success" plain @click="presentVisible = true">申请实例</el-button>
                            </el-row>
                            <el-row type="flex" justify="center" style="margin-top: 10px">
                                <el-button @click="dialogVisible = true" type="primary" plain>前往面板</el-button>
                            </el-row>
                            <el-row type="flex" justify="center" style="margin-top: 10px">
                                <el-button type="success" plain disabled>创建实例</el-button>
                            </el-row>
                        </el-card>
                    </el-col>
                </div>
            </el-collapse-transition>
        </el-row>
        <el-row class="page-row">
            <el-pagination class="page" background :pager-count="5" :hide-on-single-page="true"
                @current-change="handleCurrentChange" layout="prev, pager, next" :current-page="queryParam.pageNum"
                :page-size="queryParam.pageSize" :total="total">
            </el-pagination>
        </el-row>

        <el-drawer title="实例详情" :visible.sync="drawer" direction="rtl" @closed="drawerClosed">
            <el-descriptions :column="1" border>
                <el-descriptions-item label="实例名称">{{ drawerData.instanceName }}</el-descriptions-item>
                <el-descriptions-item label="实例镜像">{{ drawerData.image }}</el-descriptions-item>
                <el-descriptions-item label="付费模式">
                    <el-tag type="success">{{ drawerData.instancePaymentMode }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="核心">{{ drawerData.instanceCpu }}</el-descriptions-item>
                <el-descriptions-item label="内存(G)">{{ drawerData.instanceMemory }}</el-descriptions-item>
                <el-descriptions-item label="硬盘(G)">{{ drawerData.instanceDisk }}</el-descriptions-item>
                <el-descriptions-item label="联机地址">{{ linkAddress }}</el-descriptions-item>
            </el-descriptions>
        </el-drawer>

        <el-dialog center title="面板用户信息" :visible.sync="dialogVisible" width="30%">
            <el-descriptions :column="1" border>
                <el-descriptions-item label="面板用户名">{{ panelUser.panelUserName }}</el-descriptions-item>
                <el-descriptions-item label="面板用户密码">{{ panelUser.panelUserPwd }}</el-descriptions-item>
            </el-descriptions>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="goToMCSM">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog width="30%" :visible="presentVisible" @open="onOpen" @close="close" title="申请实例" center>
            <el-alert title="注意(当前处于测试阶段)" type="info" description="赠送的实例配置为1c8g15G,有效期三天" style="margin-bottom: 20px;"
                show-icon>
            </el-alert>
            <el-form ref="presentForm" :model="presentData" :rules="rules" size="medium" label-width="100px">
                <el-form-item label="名称" prop="instanceName">
                    <el-input v-model="presentData.instanceName" placeholder="请输入名称" :maxlength="30" clearable
                        :style="{ width: '100%' }"></el-input>
                </el-form-item>
                <el-form-item label="节点" prop="nodeId">
                    <el-select v-model="presentData.nodeId" placeholder="请选择节点" clearable :style="{ width: '100%' }">
                        <el-option v-for="(item, index) in nodeIdOptions" :key="index" :label="item.label"
                            :value="item.value" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="镜像" prop="image">
                    <el-select v-model="presentData.image" placeholder="请选择镜像" clearable :style="{ width: '100%' }">
                        <el-option v-for="(item, index) in imageOptions" :key="index" :label="item.label"
                            :value="item.value" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="端口" prop="port">
                    <el-select v-model="presentData.port" placeholder="请选择端口" clearable :style="{ width: '100%' }">
                        <el-option v-for="(item, index) in portOptions" :key="index" :label="item.label" :value="item.value"
                            :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="handleConfirm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            instanceVisible: false,
            dialogVisible: false,
            drawer: false,
            drawerData: {},
            linkAddress: null,
            instanceData: [],
            queryParam: {
                pageNum: 1,
                pageSize: 5,
            },
            panelUser: {
                userId: null,
                panelUserName: null,
                panelUserPwd: null,
            },
            presentData: {
                instanceName: null,
                nodeId: null,
                image: null,
                port: null
            },
            presentVisible: false,
            rules: {
                instanceName: [{
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                }],
                nodeId: [{
                    required: true,
                    message: '请选择节点',
                    trigger: 'change'
                }],
                image: [{
                    required: true,
                    message: '请选择镜像',
                    trigger: 'change'
                }],
                port: [{
                    required: true,
                    message: '请选择端口',
                    trigger: 'change'
                }],
            },
            nodeIdOptions: [],
            portOptions: [],
            imageOptions: [],
        }
    },
    created() {
        //判断token是否存在,不存在提示还未开放
        if (!localStorage.getItem('back_token')) {
            this.$message.info('该功能还未开放')
        } else {
            this.getInstanceList()
            this.getPanelUserInfo()
        }
    },
    methods: {
        async getInstanceList() {
            const { data: res } = await this.$http.get('/mc_user', { params: this.queryParam });
            if (res.code === 200) {
                this.instanceVisible = true
                this.instanceData = res.data.data
                this.queryParam.pageNum = res.data.pageNum
                this.queryParam.pageSize = res.data.pageSize
                this.total = res.data.total
            } else {
                this.$message.error('获取实例列表失败')
            }
        },
        async getPanelUserInfo() {
            const { data: res } = await this.$http.get('/mc_user/panel_user')
            if (res.code === 200) {
                this.panelUser = res.data
            } else {
                this.$message.error('获取面板用户信息失败')
            }
        },
        handleCurrentChange(val) {
            this.queryParam.pageNum = val
            this.getInstanceList()
        },
        async goToMCSM() {
            this.dialogVisible = false
            const { data: res } = await this.$http.get('/mc_user/mcsm')
            if (res.code === 200) {
                window.open("http://" + res.data)
            } else {
                this.$message.error('跳转失败')
            }
        },
        async handleClick(row) {
            this.drawer = true
            const { data: res } = await this.$http.get('/mc_user/ip')
            if (res.code !== 200) {
                this.$message.error('获取联机IP失败')
            } else {
                this.drawerData = row
                this.linkAddress = res.data + ':' + this.drawerData.port
            }
        },
        drawerClosed() {
            this.linkAddress = null
        },
        async onOpen() {
            //获取三个下拉列表数据
            const { data: res_node } = await this.$http.get('/mc_user/node')
            if (res_node.code === 200) {
                this.nodeIdOptions = res_node.data
            } else {
                this.$message.error('获取节点失败')
            }
            const { data: res_port } = await this.$http.get('/mc_user/port')
            if (res_port.code === 200) {
                this.portOptions = res_port.data
            } else {
                this.$message.error('获取端口失败')
            }
            const { data: res_image } = await this.$http.get('/mc_user/image')
            if (res_image.code === 200) {
                this.imageOptions = res_image.data
            } else {
                this.$message.error('获取镜像失败')
            }
        },
        close() {
            this.$refs['presentForm'].resetFields()
            this.presentVisible = false
        },
        handleConfirm() {
            this.$refs['presentForm'].validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.post('/mc_user/present/'+localStorage.getItem('token'), this.presentData)
                if (res.code === 200) {
                    this.$message.success('正在创建中...')
                    setTimeout(()=>{
                        location.reload()
                    },3000)
                } else {
                    this.$message.error(res.msg)
                }
                this.close()
            })
        },
    }

}
</script>
<style lang="less" scoped>
.page-row {
    display: grid;
}

.page {
    margin: 10px auto;
}
</style>